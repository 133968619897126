<template>
    <div>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <tr>
                <th style="width: 30%">スタッフ名</th>
                <th style="width: 30%">アカウント名</th>
                <th style="width: 10%">役割</th>
                <th style="width: 10%">属性</th>
                <th style="width: 10%">編集</th>
                <th style="width: 10%">削除</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="user in users">
                <tr :class="{'is-deleted': (user.attributes.deleted_at !== null)}">
                    <td>{{ user.attributes.name }}</td>
                    <td>{{ user.attributes.login_name }}</td>
                    <td>{{ castRole(user.attributes.role) }}</td>
                    <td>{{ castAttribute(user.attributes.nurse) }}</td>
                    <template v-if="user.attributes.deleted_at === null">
                        <td><a href="javascript:void(0)" @click="openEditModal(user)">編集</a></td>
                        <td><a href="javascript:void(0)" @click="openDeleteModal(user)">削除</a></td>
                    </template>
                    <template v-else>
                        <td colspan="2" class="text-center"><a href="javascript:void(0)" @click="restoreConfirm(user)">復元</a></td>
                    </template>
                </tr>
            </template>
            </tbody>
        </table>
        <pagination :meta="meta" @paginate="$emit('paginate', $event)"></pagination>
        <edit-user-modal ref="editModal" @user-has-updated="$emit('user-has-updated', $event)"></edit-user-modal>
        <delete-user-modal ref="deleteModal" @user-has-deleted="$emit('user-has-deleted', $event)"></delete-user-modal>
    </div>
</template>

<script>
import EditUserModal from "@/components/Users/EditUserModal/EditUserModal";
import DeleteUserModal from "@/components/Users/DeleteUserModal/DeleteUserModal";
import Pagination from "@/components/Pagination/Pagination";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "UserList",
    components: {DeleteUserModal, EditUserModal, Pagination},
    mixins:[ApiRouter, ErrorHandler],
    props: ['users', 'meta'],
    methods: {
        openEditModal: function (user) {
            this.$refs['editModal'].openModal(user);
        },
        openDeleteModal: function (user) {
            this.$refs['deleteModal'].openModal(user);
        },
        restoreUser: function (user) {
            this.showIndicator('復元中');

            axios.patch(this.routes.userRestore(user.id))
                .then(response => {
                    this.showSuccessPopup('復元しました');
                    this.$emit('user-has-restored', response.data.data);
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        restoreConfirm: function (user) {
            this.showRestoreConfirmPopup(() => {
                this.restoreUser(user);
            })
        }
    },
    computed: {
        castRole: function () {
            return function (role) {
                switch (parseInt(role)) {
                    case 0:
                        return '一般'
                    case 1:
                        return '管理者'
                }
            }
        },
        castAttribute: function () {
            return function (attribute) {
                switch (parseInt(attribute)) {
                    case 0:
                        return '看護師'
                    case 1:
                        return '保健師'
                }
            }
        },
    },
}
</script>

<style src="./UserList.scss" lang="scss" scoped/>
