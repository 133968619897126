<template>
    <CModal
        title="スタッフ登録"
        color="dark"
        size="lg"
        :show.sync="isOpened">
        <form>
            <div class="form">
                <v-input label="スタッフ名"
                         id="create-name"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('name')"
                         v-model="form.name">
                </v-input>
                <v-input label="アカウント名"
                         id="create-account"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('login_name')"
                         v-model="form.login_name">
                </v-input>
                <v-radio-group label="役割"
                               :errors="hasErrors('role')">
                    <v-radio label="一般"
                             name="createRole"
                             value="0"
                             id="create-general"
                             :checked="form.role === '0'"
                             :errors="hasErrors('role')"
                             @change="changeRole">
                    </v-radio>
                    <v-radio label="管理者"
                             name="createRole"
                             value="1"
                             id="create-admin"
                             :checked="form.role === '1'"
                             :errors="hasErrors('role')"
                             @change="changeRole">
                    </v-radio>
                </v-radio-group>
                <v-select id="create-nurse"
                          label="看護職"
                          :errors="hasErrors('nurse')"
                          v-model="form.nurse">
                    <option value="" :selected="form.nurse === ''">選択してください</option>
                    <option value="0" :selected="form.nurse === '0'">看護師</option>
                    <option value="1" :selected="form.nurse === '1'">保健師</option>
                </v-select>
                <v-input label="パスワード"
                         id="create-password"
                         type="password"
                         placeholder="入力必須"
                         :errors="hasErrors('password')"
                         v-model="form.password">
                </v-input>
                <v-input label="確認用パスワード"
                         id="create-password-confirmation"
                         type="password"
                         placeholder="入力必須"
                         :errors="hasErrors('password_confirmation')"
                         v-model="form.password_confirmation">
                </v-input>
            </div>
        </form>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="createUser" :disabled="sending">登録</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import VRadioGroup from "@/components/Forms/VRadioGroup";
import VRadio from "@/components/Forms/VRadio";
import VSelect from "@/components/Forms/VSelect";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "CreateUserModal",
    components: {VSelect, VRadio, VRadioGroup, VInput},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            form: {
                name: '',
                login_name: '',
                role: '',
                nurse: '',
                password: '',
                password_confirmation: ''

            }
        }
    },
    methods: {
        openModal: function () {
            this.isOpened = true;
            this.initForm();
            this.clearErrors();
        },
        initForm: function () {
            this.form.name = '';
            this.form.login_name = '';
            this.form.role = '';
            this.form.nurse = '';
            this.form.password = '';
            this.form.password_confirmation = '';
        },
        changeRole: function ($event) {
            this.form.role = $event;
        },
        createUser: function () {
            this.sending = true;

            axios.post(this.routes.users, this.form)
                .then(response => {
                    this.$emit('user-has-created', response.data.data);
                    this.showSuccessPopup('登録しました')
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
}
</script>

<style scoped>

</style>
