<template>
    <CModal
        title="スタッフ編集"
        color="dark"
        size="lg"
        :show.sync="isOpened">
        <form>
            <div class="form">
                <v-input label="スタッフ名"
                         id="edit-name"
                         type="text"
                         is-invalid
                         placeholder="入力必須"
                         :errors="hasErrors('name')"
                         v-model="form.name">
                </v-input>
                <v-input label="アカウント名"
                         id="edit-account"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('login_name')"
                         v-model="form.login_name">
                </v-input>
                <v-radio-group label="役割">
                    <v-radio label="一般"
                             name="editRule"
                             value="0"
                             id="edit-general"
                             :checked="form.role === '0'"
                             :errors="hasErrors('role')"
                             @change="changeRole">
                    </v-radio>
                    <v-radio label="管理者"
                             name="editRule"
                             value="1"
                             id="edit-admin"
                             :checked="form.role === '1'"
                             :errors="hasErrors('role')"
                             @change="changeRole">
                    </v-radio>
                </v-radio-group>
                <v-select id="edit-nurse"
                          label="看護職"
                          :errors="hasErrors('nurse')"
                          v-model="form.nurse">
                    <option value="" :selected="form.nurse === ''">選択してください</option>
                    <option value="0" :selected="form.nurse === '0'">看護師</option>
                    <option value="1" :selected="form.nurse === '1'">保健師</option>
                </v-select>
                <v-input label="パスワード"
                         id="edit-password"
                         type="password"
                         placeholder="パスワードを変更する場合のみ入力してください"
                         :errors="hasErrors('password')"
                         v-model="form.password">
                </v-input>
                <v-input label="確認用パスワード"
                         id="edit-password-confirmation"
                         type="password"
                         placeholder="パスワードを変更する場合のみ入力してください"
                         :errors="hasErrors('password_confirmation')"
                         v-model="form.password_confirmation">
                </v-input>
            </div>
        </form>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="updateUser" :disabled="sending">更新</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import VRadioGroup from "@/components/Forms/VRadioGroup";
import VRadio from "@/components/Forms/VRadio";
import VSelect from "@/components/Forms/VSelect";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "EditUserModal",
    components: {VSelect, VRadio, VRadioGroup, VInput},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            id: '',
            form: {
                name: '',
                login_name: '',
                role: '',
                nurse: '',
                password: '',
                password_confirmation: ''
            }
        }
    },
    methods: {
        openModal: function (user) {
            this.isOpened = true;
            this.initForm(user);
            this.clearErrors();
        },
        initForm: function (user) {
            this.id = user.id;
            this.form.name = user.attributes.name;
            this.form.login_name = user.attributes.login_name;
            this.form.role = user.attributes.role.toString();
            this.form.nurse = user.attributes.nurse.toString();
            this.form.password = '';
            this.form.password_confirmation = '';
        },
        changeRole: function ($event) {
            this.form.role = $event;
        },
        updateUser: function () {
            this.showIndicator('更新中');
            this.sending = true;

            let formData = new FormData();

            let config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            formData.append('name', this.form.name);
            formData.append('login_name', this.form.login_name);
            formData.append('role', this.form.role);
            formData.append('nurse', this.form.nurse);
            if (this.form.password !== '') formData.append('password', this.form.password);
            if (this.form.password_confirmation !== '') formData.append('password_confirmation', this.form.password_confirmation);

            formData.append('_method', 'PATCH');

            axios.post(this.routes.user(this.id), formData, config)
                .then(response => {
                    this.$emit('user-has-updated', response.data.data);
                    this.showSuccessPopup('更新しました');
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
    },
}
</script>

<style scoped>

</style>
