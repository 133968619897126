<template>
    <div class="container-fluid">
        <div class="row pb-2">
            <div class="col-6">
                <h3>#スタッフ一覧</h3>
            </div>
            <div class="col-6">
                <button type="button"
                        class="btn btn-outline-dark float-right mb-2"
                        @click="openCreateModal">＋
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <user-list :users="users"
                           :meta="meta"
                           @paginate="paginate"
                           @user-has-updated="updateUser"
                           @user-has-deleted="deleteUser"
                           @user-has-restored="restoreUser">
                </user-list>
            </div>
        </div>
        <create-user-modal ref="createModal" @user-has-created="createUser"></create-user-modal>
    </div>
</template>

<script>
import UserList from "@/components/Users/UserList/UserList";
import CreateUserModal from "@/components/Users/CreateUserModal/CreateUserModal";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "Users",
    components: {CreateUserModal, UserList},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            meta: {},
            users: {}
        }
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            axios.get(this.routes.users)
                .then(response => {
                    this.meta = response.data.meta;
                    this.users = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        openCreateModal: function () {
            this.$refs['createModal'].openModal();
        },
        createUser: function (user) {
            this.users.unshift(user);
        },
        updateUser: function ($event) {
            const index = this.users.findIndex(function (user) {
                return user.id === $event.id;
            });
            if (index !== -1) {
                this.users.splice(index, 1, $event);
            }
        },
        deleteUser: function ($event) {
            const index = this.users.findIndex(function (user) {
                return user.id === $event.id;
            });
            this.users.splice(index, 1);

            this.users.push($event);
        },
        restoreUser: function ($event) {
            const index = this.users.findIndex(function (user) {
                return user.id === $event.id;
            });
            if (index !== -1) {
                this.users.splice(index, 1, $event);
            }
        },
        paginate: function (page) {
            axios.get(this.routes.users, {
                params: {
                    page: page
                }
            })
                .then(response => {
                    this.meta = response.data.meta;
                    this.users = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        }
    }
}
</script>

<style scoped>

</style>
