<template>
    <CModal
        title="スタッフ削除"
        color="dark"
        size="sm"
        :show.sync="isOpened">
        <div class="py-1">スタッフ名：{{ name }}</div>
        <div class="py-1">アカウント名：{{ login_name }}</div>
        <div class="py-1">役割：{{ castRole(role) }}</div>
        <div class="py-1">属性：看護師</div>
        <template #footer>
            <button type="button" class="btn btn-danger" @click="deleteConfirm" :disabled="sending">削除</button>
        </template>
    </CModal>
</template>

<script>
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "DeleteUserModal",
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            id: '',
            name: '',
            login_name: '',
            role: '',
        }
    },
    methods: {
        openModal: function (user) {
            this.isOpened = true;
            this.id = user.id;
            this.name = user.attributes.name;
            this.login_name = user.attributes.login_name;
            this.role = user.attributes.role;
        },
        deleteUser: function () {
            this.sending = true;
            this.showIndicator('削除中');

            axios.delete(this.routes.user(this.id))
                .then(response => {
                    this.$emit('user-has-deleted', response.data.data);
                    this.showSuccessPopup('削除しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        deleteConfirm: function () {
            this.showDeleteConfirmPopup(() => {
                this.deleteUser();
            })
        }
    },
    computed: {
        castRole: function () {
            return function (role) {
                switch (parseInt(role)) {
                    case 0:
                        return '一般'
                    case 1:
                        return '管理者'
                }
            }
        },
        castAttribute: function () {
            return function (attribute) {
                switch (parseInt(attribute)) {
                    case 0:
                        return '看護師'
                    case 1:
                        return '保健師'
                }
            }
        },
    },
}
</script>

<style scoped>

</style>
